import { ImageServiceStrategyInterface, ImageStrategyParams } from '../../types';

const gumletSupportedFormats = ['auto', 'jpg', 'png', 'webp', 'avif', 'heif', 'raw', 'gif'];

export class GumletStrategy implements ImageServiceStrategyInterface {
  private gumletUrl: string;
  constructor(gumletUrl: string) {
    if (!gumletUrl.startsWith('https://')) {
      throw new Error(`Gumlet url's must start with https (given: ${gumletUrl}) `);
    }
    if (gumletUrl.endsWith('/')) {
      throw new Error(`Gumlet url's cannot include trailing slash (given: ${gumletUrl}) `);
    }
    this.gumletUrl = gumletUrl;
  }
  getUrl(imgKey: string, params: ImageStrategyParams): string {
    const { width, format, quality: q } = params;
    const qp = [
      typeof width === 'number' ? `w=${width}` : null,
      typeof q === 'number' && q > 50 && q <= 100 ? `q=${q}` : null,
      typeof format === 'string' && gumletSupportedFormats.includes(format) ? `format=${format}` : null,
    ]
      .filter((p) => p !== null)
      .join('&');
    return `${this.gumletUrl}/${imgKey}?${qp}`;
  }
}
