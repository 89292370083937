import { ImageServiceStrategyInterface, ImageStrategyParams } from '../../types';

const defaultNextJsQuality = 75;

export class NextjsImageStrategy implements ImageServiceStrategyInterface {
  getUrl(imgPath: string, params: ImageStrategyParams): string {
    const { width, quality = defaultNextJsQuality } = params;
    const qp = [
      `url=${encodeURIComponent(imgPath)}`,
      typeof width === 'number' ? `w=${width}` : null,
      quality ? `q=${quality}` : null,
    ]
      .filter((p) => p !== null)
      .join('&');
    return `/_next/image?${qp}`;
  }
}
