import { VideoImageStrategyInterface, VideoImageStrategyParams } from '../../types';

/**
 * Strategy for video thumbnail based on our own service-thumbnail
 * @see https://github.com/sortlist/service-thumbnail
 */
export class VideoThumbnailStrategy implements VideoImageStrategyInterface {
  private vtUrl: string;
  constructor(vtUrl: string) {
    if (!vtUrl.startsWith('https://')) {
      throw new Error(`VideoThumbnail service url's must start with https (${vtUrl})`);
    }
    if (vtUrl.endsWith('/')) {
      throw new Error(`VideoThumbnail service url's cannot include trailing slash (${vtUrl})`);
    }
    this.vtUrl = vtUrl;
  }

  getUrl(videoUrl: string, params: VideoImageStrategyParams): string {
    return `${this.vtUrl}/${params.provider}?url=${encodeURIComponent(videoUrl)}`;
  }
}
