import { assertString } from '@sortlist-frontend/utils';

import {
  GumletStrategy,
  NextjsImageStrategy,
  NoOptimizationStrategy,
  VideoThumbnailStrategy,
} from './image-optimiziation';
import { ImageServiceConfig, ImageServiceStrategyInterface } from './types';

const localImagesAllowedWidths = [48, 64, 150, 400, 640, 750, 828, 1080, 1200, 1920, 2048, 3840];

const ACCEPTED_STRATEGIES = [
  'nextjs://local',
  'ignore://local',
  'gumlet://sortlist-img.gumlet.io',
  'gumlet://sortlist-img-test.gumlet.io',
];
// For local images it depends on setup, please see NEXT_PUBLIC_LOCAL_IMAGE_STRATEGY
export const createStrategyFromEnv = (): {
  strategy: ImageServiceStrategyInterface;
  removeBasePath: boolean;
} => {
  const strategyDsn = process.env.NEXT_PUBLIC_LOCAL_IMAGE_STRATEGY ?? 'ignore://local';

  assertString(strategyDsn, 'Config error, env NEXT_PUBLIC_LOCAL_IMAGE_STRATEGY must be a string');

  if (ACCEPTED_STRATEGIES.includes(strategyDsn)) {
    const [driver, host] = strategyDsn.split('://');

    switch (driver) {
      case 'gumlet':
        return {
          strategy: new GumletStrategy(`https://${host}`),
          removeBasePath: true,
        };
      case 'nextjs':
        return {
          strategy: new NextjsImageStrategy(),
          removeBasePath: false,
        };
      default:
        return {
          strategy: new NoOptimizationStrategy(),
          removeBasePath: false,
        };
    }
  }
  throw new Error(`'config error': can't parse image strategy '${strategyDsn}'`);
};

// Keep it hoisted outside: it helps webpack to optimize the code (parseDsn is not bundled / problem with safari 13)
const localImageStrategyParams = createStrategyFromEnv();

export const imageServiceConfig: ImageServiceConfig = {
  s3BucketsMap: [
    // When we save images in core-api database, they are stored
    // in s3 into a bucket called 'sortlist-core-api'. The corresponding
    // gumlet service url is 'https://sortlist.gumlet.io'
    { s3Bucket: 'sortlist-core-api', strategy: new GumletStrategy('https://sortlist.gumlet.io') },
    {
      s3Bucket: 'sortlist-core-api-staging', // The s3 bucket for the staging core-db
      strategy: new GumletStrategy('https://sortlist-test.gumlet.io'),
    },
  ],
  videoProviders: {
    youtube: new VideoThumbnailStrategy('https://thumbnail.sortlist.com'),
    vimeo: new VideoThumbnailStrategy('https://thumbnail.sortlist.com'),
  },
  localImagesAllowedWidths,
  localImagesMap: [
    // When we save images in ./public/_img they can be optimized by
    // - [in production] Gumlet service -> 'https://sortlist-img.gumlet.io'
    // - [in staging/dev/local] By nextjs -> '/_next/...'
    { basePath: '/_img', ...localImageStrategyParams },
  ],
  // default width if not specified
  defaultWidth: 1080,
  // Keep it auto to allow proper browser negotiation
  defaultFormat: 'auto',
  security: {
    checkValidUrl: true,
    replaceInvalidUrl: '',
  },
};
