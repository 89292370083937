import { isBrowser } from '@sortlist-frontend/utils';
import Analytics from 'analytics';

import {
  DISABLE_AMPLITUDE_ON_TRACK_EVENTS,
  DISABLED_INTEGRATIONS,
  disabledAmplitudeForSpecificEvent,
  ENABLED_INTEGRATIONS,
  enableDisabledIntegrations,
} from './integrations';
import segmentPlugin from './segmentPlugin';

export type APPS = 'appManager' | 'appAgency' | 'appPublic' | 'appMatch';

const DEFAULT_PROPS_TRACKER = {
  track: () => {},
  trackWithCallback: () => {},
  page: () => {},
  identify: () => {},
  ready: false,
};
const CALLBACK_TIMEOUT = 2000;

export const analytics = (integrations: Record<string, boolean>, excludedIntegrations?: string[]) => {
  const segmentKey = process.env.NEXT_PUBLIC_SEGMENT_KEY;

  if (!segmentKey) return Analytics({});

  const analytics = Analytics({
    debug: true,
    plugins: [
      segmentPlugin({
        writeKey: segmentKey as string,
        customScriptSrc: process.env.NEXT_PUBLIC_SEGMENT_PROXY as string,
        integrations: {
          ...integrations,
          ...ENABLED_INTEGRATIONS,
          ...enableDisabledIntegrations(DISABLED_INTEGRATIONS),
        },
        excludedIntegrations,
      }) as Record<string, unknown>,
    ],
  });

  if (isBrowser()) {
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore False positive when running type-check
    window.analyticsInstance = analytics;
  }

  return analytics;
};

const execWithTimeout = (callback: (resolve: (value?: unknown) => void, reject: (reason?: string) => void) => void) => {
  return new Promise((resolve, reject) => {
    const timer = setTimeout(() => {
      reject('Execution took too much time');
    }, CALLBACK_TIMEOUT);

    callback(
      // Either the tracker passes and it resolves naturally
      (value) => {
        clearTimeout(timer);
        resolve(value);
      },
      // Or the timeout is reached and we force it to stop
      (error) => {
        clearTimeout(timer);
        reject(error);
      },
    );
  });
};

export const getAnalyticsInstance = ({ app }: { app: APPS }) => {
  if (isBrowser()) {
    const analyticsInstance = window.analyticsInstance || DEFAULT_PROPS_TRACKER;

    return {
      ...analyticsInstance,
      ready: !!window?.analyticsInstance,
      track: (trackerName: string, trackerProps: Record<string, unknown>, options?: Record<string, unknown>) => {
        analyticsInstance.track(
          trackerName,
          { app, ...trackerProps },
          {
            ...options,
            ...disabledAmplitudeForSpecificEvent(trackerName, DISABLE_AMPLITUDE_ON_TRACK_EVENTS),
          },
        );
      },
      identify: (userId: string, trackerProps: Record<string, unknown>, options?: Record<string, unknown>) => {
        analyticsInstance.identify(userId, { app, ...trackerProps }, options);
      },
      // Some trackers are performed before event that could block them (like 'briefingCompleted' done
      // just before changing the page), so we need to make sure that they were performed before allowing
      // the next actions. Still, we set a timeout to make sure we don't disrupt the service if tracker
      // doesn't pass for whatever reason (Segment down, low internet connection...). Use this one only if
      // necessary, cause it's adding some overhead.
      trackWithCallback: async (
        trackerName: string,
        trackerProps: Record<string, unknown>,
        callback: () => void,
        options?: Record<string, unknown>,
      ) => {
        await execWithTimeout(async (resolve, _reject) => {
          await analyticsInstance.track(trackerName, { app, ...trackerProps }, { ...options, synchronous: true });
          resolve();
        }).finally(() => {
          // No matter what happened before (execution or timeout), we'll perform the callback
          callback();
        });
      },
      page: (props: Record<string, unknown>, options: Record<string, unknown>) => {
        analyticsInstance.page(
          { app, ...props },
          {
            ...options,
            ...disabledAmplitudeForSpecificEvent((props.category as string) || '', DISABLE_AMPLITUDE_ON_TRACK_EVENTS),
          },
        );
      },
    };
  }

  return DEFAULT_PROPS_TRACKER;
};
