import { isNonEmptyString } from '@sortlist-frontend/utils';

import {
  ImageServiceConfig,
  ImageServiceStrategyInterface,
  ImageStrategyParams,
  LocalImageSrcOptimizationParams,
  VideoParsedUrl,
} from '../types';
import { S3ParsedUrl, S3UrlParser, VideoUrlParser } from '../url-detection';

const defaultOptimizationParams: LocalImageSrcOptimizationParams = {
  width: 1024,
  format: 'auto',
};

const defaultImageServiceConfig = {
  security: {
    checkValidUrl: true,
    replaceInvalidUrl: '',
  },
} as const;

export class ImageOptimizer {
  private defaultParams: ImageStrategyParams;
  private imgServiceConfig: Required<ImageServiceConfig>;

  constructor(config: ImageServiceConfig, defaultParams?: ImageStrategyParams) {
    this.imgServiceConfig = {
      ...defaultImageServiceConfig,
      ...config,
    };
    this.defaultParams = {
      ...defaultOptimizationParams,
      ...{
        width: defaultParams?.width ?? this.imgServiceConfig.defaultWidth,
        format: defaultParams?.format ?? this.imgServiceConfig.defaultFormat,
      },
    };
  }

  /**
   * Get the image src from an local image stored in ./public.
   *
   * @throws Error when path does not start with a '/'
   */
  fromPath = (imgPath: string, params?: LocalImageSrcOptimizationParams): string => {
    if (imgPath.startsWith('https://') || imgPath.startsWith('http://')) {
      throw new Error(`fromPath(path) works only with local files, received '${imgPath}'`);
    }
    if (!imgPath.startsWith('/')) {
      throw new Error(`fromPath(path) requires a leading '/', received '${imgPath}'`);
    }
    if (imgPath.length < 6) {
      throw new Error(`fromPath(path) requires min 5 chars, received '${imgPath}'`);
    }
    const { localImagesMap } = this.imgServiceConfig;

    let found: {
      basePath: string;
      strategy: ImageServiceStrategyInterface;
      removeBasePath: boolean;
    } | null = null;
    let i = 0;
    while (!found && i < localImagesMap.length) {
      if (imgPath.startsWith(localImagesMap[i].basePath)) {
        found = {
          strategy: localImagesMap[i].strategy,
          basePath: localImagesMap[i].basePath,
          removeBasePath: localImagesMap[i].removeBasePath,
        };
      }
      i++;
    }
    if (found !== null) {
      const { width, format = defaultOptimizationParams.format } = {
        ...this.defaultParams,
        ...params,
      };
      const { removeBasePath, basePath, strategy } = found;
      const path = removeBasePath ? imgPath.slice(basePath.length + 1) : imgPath;
      return strategy.getUrl(path, {
        width: width,
        format,
      });
    }
    return imgPath;
  };

  /**
   * getUrl will return an optimized/resized version of the given image url under
   * some conditions:
   * - 1. When s3 bucket is detected, will return gumlet.. url
   */
  fromUrl = (url: string, params?: ImageStrategyParams): string => {
    // cause most url's come from api and we're not sure of the "real" type
    if (!isNonEmptyString(url)) {
      return url;
    }
    const paramsWithDefaults = { ...this.defaultParams, ...params };
    if (url.match(/https?:\/\//i) || url.startsWith('//')) {
      return this.fromAbsoluteUrl(url, paramsWithDefaults);
    }
    return url;
  };

  /**
   * Absolute Url's contain the domain, ie: 'https://s3.com/test/test.jpg'
   */
  private fromAbsoluteUrl = (url: string, params: ImageStrategyParams): string => {
    const s3Object = S3UrlParser.parse(url);
    if (s3Object !== null) {
      return this.getS3GumletUrl(s3Object, params) ?? url;
    }

    const videoObject = VideoUrlParser.parse(url);
    if (videoObject !== null) {
      return this.getVideoThumbnailUrl(videoObject, params);
    }

    if (this.imgServiceConfig.security.checkValidUrl) {
      // Security: protect from possible injection attacks after video parsing
      try {
        new URL(url);
      } catch (e) {
        return this.imgServiceConfig.security.replaceInvalidUrl;
      }
    }

    return url;
  };

  private getS3GumletUrl = (s3Object: S3ParsedUrl, params: ImageStrategyParams): string | null => {
    const { s3BucketsMap } = this.imgServiceConfig;
    const { bucket, key } = s3Object;
    let foundStrategy: ImageServiceStrategyInterface | null = null;
    let i = 0;
    while (!foundStrategy && i < s3BucketsMap.length) {
      if (s3BucketsMap[i].s3Bucket === bucket) {
        foundStrategy = s3BucketsMap[i].strategy;
      }
      i++;
    }
    if (foundStrategy !== null) {
      const { width, quality, format = defaultOptimizationParams.format } = params;
      return foundStrategy.getUrl(`${bucket}/${key}`, {
        width,
        format,
        quality,
      });
    }
    return null;
  };

  private getVideoThumbnailUrl = (videoParsedUrl: VideoParsedUrl, params: ImageStrategyParams): string => {
    const { provider, url } = videoParsedUrl;
    return this.imgServiceConfig.videoProviders[provider].getUrl(url, {
      ...params,
      provider: videoParsedUrl.provider,
      key: videoParsedUrl.key,
    });
  };
}
