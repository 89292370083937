import { ImageSourceHelper } from '@sortlist-frontend/media';
import { DefaultSeoProps } from 'next-seo';

import { GenericPageSeoProps } from '_components/seo/generic-page-seo';

const seoImages = {
  default: ImageSourceHelper.fromPath('/_img/default/default-meta.webp', { width: 1200 }),
};

export const getSeoImage = (key: keyof typeof seoImages): string => {
  return seoImages[key];
};

export const seoConfig: {
  genericPageSeo: Required<Pick<GenericPageSeoProps, 'siteName' | 'image'>>;
  defaultNextSeo: DefaultSeoProps;
} = {
  genericPageSeo: {
    siteName: 'Sortlist',
    image: getSeoImage('default'),
  },
  defaultNextSeo: {
    twitter: {
      handle: '@charlesdg',
      site: '@Sortlist',
      cardType: 'summary',
    },
    facebook: {
      appId: '614655231979033',
    },
    additionalMetaTags: [
      {
        property: 'fb:admins',
        content: '70569527',
      },
    ],
  },
} as const;
