import { isNonEmptyString } from '@sortlist-frontend/utils';

import { VideoParsedUrl } from '../types';

const vimeoHostnameRegExp = /vimeo\.com|fpdl\.vimeocdn\.com|gcs-vimeo\.akamaized\.net$/;
const youtubeHostnameRegExp = /(?:youtube\.[a-z]{1,4}|youtu\.be|youtube\.googleapis\.com)$/;

const wellknownFileExt = ['jpg', 'webp', 'png', 'gif', 'avif', 'svg', 'txt', 'html'];

const youtubeKeyPattern = /[a-z\d]{3,20}/i;
const vimeoKeyPattern = /[a-z\d]{3,20}/;
const vimeoVideoKeyPattern = /^\/\d+(?:\/[a-z\d]{3,20})?$/;
const vimeoAlbumKeyPattern = /^(?:\/album\/[a-z\d]{3,20})?\/video\/\d+$/;

export class VideoUrlParser {
  static parse = (url: string): VideoParsedUrl | null => {
    if (!isNonEmptyString(url) || !url.startsWith('https://')) {
      return null;
    }
    let parsedUrl: URL;
    try {
      parsedUrl = new URL(url);
    } catch (e) {
      return null;
    }
    const { pathname } = parsedUrl;
    const ext = pathname !== null ? pathname.substring(pathname.lastIndexOf('.') + 1, pathname.length) ?? '' : '';
    if (wellknownFileExt.includes(ext)) {
      return null;
    }
    const ytKey = VideoUrlParser.inferYoutubeKey(parsedUrl);
    if (ytKey !== null) {
      return {
        provider: 'youtube',
        key: ytKey,
        url,
      };
    }
    const vimKey = VideoUrlParser.inferVimeoKey(parsedUrl);
    if (vimKey !== null) {
      return {
        provider: 'vimeo',
        key: vimKey,
        url,
      };
    }
    return null;
  };

  private static inferYoutubeKey = (url: URL): string | null => {
    const { hostname, pathname, searchParams } = url;
    if (!youtubeHostnameRegExp.test(hostname)) {
      return null;
    }
    const v = searchParams.get('v') ?? pathname.split('/').pop();
    if (v && youtubeKeyPattern.test(v)) {
      return v;
    }
    return null;
  };

  private static vimeoKey = (key?: string | null) => (key && vimeoKeyPattern.test(key) ? key : null);

  private static inferVimeoKey = (url: URL): string | null => {
    const { hostname, pathname } = url;
    if (!vimeoHostnameRegExp.test(hostname)) {
      return null;
    }

    if (pathname.match(vimeoVideoKeyPattern)) {
      const pathElements = pathname.split('/');

      if (pathElements && pathElements?.length > 1) {
        return VideoUrlParser.vimeoKey(pathElements[1]);
      }
    } else if (pathname.match(vimeoAlbumKeyPattern)) {
      return VideoUrlParser.vimeoKey(pathname.split('/').pop());
    }

    return null;
  };
}
