export type S3ParsedUrl = {
  key: string;
  bucket: string;
  url: string;
};

const s3Regexp = /^https:\/\/(([^.])+)?(s3|\.s3\.)(.*)(amazonaws\.com)\/((.*)\/)?([a-z\d]+)$/i;

export class S3UrlParser {
  /**
   * Parse the provided url and return the s3 properties if valid or null
   */
  static parse = (url: string): S3ParsedUrl | null => {
    const matches = url.match(s3Regexp);

    if (matches === null) {
      return null;
    }

    return {
      key: matches?.[8],
      bucket: matches?.[1] ?? matches?.[7],
      url: url,
    };
  };
}
