export const ENABLED_INTEGRATIONS = {
  'Segment.io': true,
  'Google Analytics': true,
  ActiveCampaign: true,
  Amplitude: true,
  bigquery: true,
};

export const DISABLED_INTEGRATIONS = {
  'Bing Ads': false,
  'Facebook Pixel': false,
  'Google AdWords Remarketing Lists (Customer Match)': false,
  'Google Tag Manager': false,
  Hotjar: false,
  HubSpot: false,
  Intercom: false,
  'Linkedin Insight Tag': false,
  Slack: false,
  Leadforensics: false,
  June: false,
};

export const enableDisabledIntegrations = (integrations: Record<string, boolean>) => {
  const enabledIntegrations: Record<string, boolean> = {};
  Object.keys(integrations).forEach((current) => {
    enabledIntegrations[current] = true;
  });
  return enabledIntegrations;
};

export const DISABLE_AMPLITUDE_ON_TRACK_EVENTS = [
  'searchCtaLoaded',
  'ab',
  'agencyLongtailsLoaded',
  'agencyCardClicked',
];

export const DISABLE_AMPLITUDE_ON_PAGE_EVENTS = ['directory', 'agency-profile'];

export const disabledAmplitudeForSpecificEvent = (eventName: string, events: string[]) => {
  return events.includes(eventName)
    ? {
        integrations: {
          All: false,
          ...ENABLED_INTEGRATIONS,
          ...enableDisabledIntegrations(DISABLED_INTEGRATIONS),
          Amplitude: false,
        },
      }
    : {};
};
